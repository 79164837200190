import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import MODALS from 'src/utils/modalsConstants';
import Button from 'components/Button';
import ContactUsModal from 'components/ContactUsModal';
import { SEND_CONTACT_SPECIALIST_EMAIL } from 'services/graphql/queries/general-emails';
import Alert from 'components/Alert';
import { useMutation } from '@apollo/client';
// import * as amplitude from '@amplitude/analytics-browser';
import { useAuth0 } from '@auth0/auth0-react';
const OpenFormAction = ({
  callToAction: {
    style,
    buttonText,
    id,
    action: { form },
  },
  prevAction,
  linkStyle,
  buttonStyle,
  extraStyle,
  isTextWithOrPrefix,
  isTermsAndCondition,
}) => {
  const [contactUsModal, setContactUsModal] = useState(false);
  const [isTerms, setIsTerms] = useState(false);
  const toggleContactUsModal = () => setContactUsModal(!contactUsModal);
  const [sendEmail, { error: emailError }] = useMutation(
    SEND_CONTACT_SPECIALIST_EMAIL
  );
  const { user } = useAuth0();
  const pages = {
    [MODALS.ContactUs]: 'Contact Us',
    [MODALS.Consignment]: 'Consignment Info',
    [MODALS.CollectionsAndEstates]: 'Collections & Estates',
    [MODALS.PrivateSales]: 'Private Sales',
  };
  const page = pages[form];

  useEffect(() => {
    const { search, pathname } = window.location;
    if (search && search.includes('form') && pathname === '/contact-us') {
      setContactUsModal(true);
      if (search && search.includes('terms')) setIsTerms(true);
    }
  }, []);

  return (
    <>
      {emailError && <Alert color="danger" msg={emailError.message} />}
      <Button
        type={
          style?.includes('Button')
            ? `${buttonStyle} ${extraStyle}`
            : `${linkStyle} ${extraStyle}`
        }
        key={`heroSectionButton_${id}`}
        handleClick={toggleContactUsModal}
      >
        {prevAction?.includes('Button') && style === 'Text'
          ? `${isTextWithOrPrefix ? 'Or, ' : ''}${buttonText}`
          : buttonText}
      </Button>
      <ContactUsModal
        isOpen={contactUsModal}
        isTerms={isTerms}
        submit={async fields => {
          try {
            await sendEmail({
              variables: {
                fields: {
                  ...fields,
                  page: page,
                  title: isTerms ? 'Contact Us' : 'Email a Specialist',
                  isTermsAndCondition: isTerms,
                },
              },
            });

            // amplitude.track(
            //   `${
            //     process.env.NODE_ENV === 'development' ? 'dev-' : ''
            //   } Ask Question`,
            //   { email: user?.email }
            // );
            toggleContactUsModal();
            if (window.dataLayer)
              window.dataLayer.push({
                event: 'contact-us-submit-form',
              });
            await navigate(window.location.pathname, {
              state: { emailSent: true },
            });
          } catch (error) {
            toggleContactUsModal();
          }
        }}
        toggle={toggleContactUsModal}
      />
    </>
  );
};

export default OpenFormAction;
