import React from 'react';
import Button from 'components/Button';
import { externalLinkPattern } from 'shared/helpers/patterns';
// import { amplitudeEvents, pagesTitles, trackEvent } from 'utils/amplitude';
import { useAuth0 } from '@auth0/auth0-react';

const RedirectAction = ({
  callToAction: {
    style,
    buttonText,
    id,
    action: { url, openInANewTab },
  },
  prevAction,
  linkStyle,
  buttonStyle,
  extraStyle,
  isTextWithOrPrefix,
  userIsnotAuthenticated = false,
  title,
  pageTitle,
}) => {
  const { user } = useAuth0();
  const redirectUrl = userIsnotAuthenticated
    ? userIsnotAuthenticated === 'unverified'
      ? '/auth/unverified'
      : '/auth/login'
    : url;
  return openInANewTab || externalLinkPattern.test(url) ? (
    <a
      href={url}
      className={`${
        style?.includes('Button')
          ? `${buttonStyle} ${extraStyle}`
          : `${linkStyle} ${extraStyle}`
      } button`}
      target={openInANewTab ? '_blank' : '_self'}
      rel="noreferrer"
      // onClick={() => {
      //   if (buttonText === 'View Online Catalogue') {
      //     trackEvent(amplitudeEvents.VIEW_CATALOGUE_PAGE, {
      //       email_address: user?.email,
      //       auction_name: title,
      //     });
      //   } else if (
      //     pageTitle === pagesTitles.CONSIGN &&
      //     buttonText === 'Submit Your Vehicle'
      //   ) {
      //     trackEvent(amplitudeEvents.SUBMIT_YOUR_VEHICLE_CONSIGN, {
      //       email_address: user?.email,
      //     });
      //   }
      // }}
    >
      {prevAction?.includes('Button') && style === 'Text'
        ? `${isTextWithOrPrefix ? 'Or, ' : ''}${buttonText}`
        : buttonText}
    </a>
  ) : (
    <Button
      type={
        style?.includes('Button')
          ? `${buttonStyle} ${extraStyle}`
          : `${linkStyle} ${extraStyle}`
      }
      key={`heroSectionButton_${id}`}
      linkAsButton={true}
      path={redirectUrl}
      // handleClick={() => {
      //   if (buttonText === 'View Online Catalogue') {
      //     trackEvent(amplitudeEvents.VIEW_CATALOGUE_PAGE, {
      //       email_address: user?.email,
      //       auction_name: title,
      //     });
      //   } else if (
      //     pageTitle === pagesTitles.CONSIGN &&
      //     buttonText === 'Submit Your Vehicle'
      //   ) {
      //     trackEvent(amplitudeEvents.SUBMIT_YOUR_VEHICLE_CONSIGN, {
      //       email_address: user?.email,
      //     });
      //   }
      // }}
    >
      {prevAction?.includes('Button') && style === 'Text'
        ? `${isTextWithOrPrefix ? 'Or, ' : ''}${buttonText}`
        : buttonText}
    </Button>
  );
};

export default RedirectAction;
